import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import MasterService from "@/core/services/master.service";

export default {
  components: {
    // Dropdown1
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentYear"
    ])
  },

  data() {
    return {
      // :core
      dataUser: {},
      searchMain: {},
      // :end core

      // :last 10 transaction
      tContents: [],
      tContentsTotal: 1,
      tContentsHeader: [
        { text: 'Role', align: 'start', value: 'nama', sortable: false},
        { text: 'Type Role', align: 'start', value: 'tipe', sortable: false},
        { text: 'Kode', align: 'start', value: 'kode', sortable: false},
        { text: 'Module', align: 'start', value: 'module_nama', sortable: false},
        { text: 'Kode', align: 'start', value: 'module_kode', sortable: false},
        { text: 'Permision', align: 'start', value: 'name', sortable: false},
        { text: 'Status', align: 'start', value: 'module_status', sortable: false},
        { text: 'Actions', align: 'end', value: 'actions', sortable: false},
        
      ],

      tContentsOptions: {},
      tContentsLoading: false,
      tContentsDisabled: false,
      tContentsPaginations: [5,10,15,25,50,100],
      tContentsSearch: {},
      // :end last 10 transaction

      // :additional
      title: "Role",
      title_form: "",
      dialogDetail: false,
      formDetail: {},
      dialog: false,
      formValid: false,
      form: {},
      selectedModule: [],
      permisionObj  : [],
      selectedModuleTree: [
        
      ],
      
      loadingModule: false,
      dataModule: [],
      // :end additional

      // :validation
      radioValidation: [v => v != null || 'This field is required'],
      kodeValidation: [v => ( v && v.length > 9 ) || "This field must have 10 characters", v => !!v || 'This field is required'],
      commonValidation: [v => !!v || 'This field is required'],
      // :end validation
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: "/" },
      { title: "Role" }
    ]),

    this.dataUser = this.currentUser;
    this.searchMain.tahun = this.currentYear;
    this.searchMain.admin_id = Number(this.currentUser.id);
    
    this.loadMainContent();
    this.loadModule();
  },

  watch: {
    tContentsOptions: {
      handler () {
        this.loadMainContent();
      },
      deep: true,
    },
    // selectedModuleTree(val){
    //   console.log("selectedModuleTree");
    //   console.log(val);
    // }
  },

  methods: {
    getMainList() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.tContentsOptions;
        
        let dataSort = {};
        let isSort = sortDesc.length;
        if(isSort > 0){
          for(let i=0; i<isSort; i++){
            let item1 = sortBy[i];
            let item2 = sortDesc[i];
            
            if(item2 == true){
              dataSort[item1] = "DESC";
            }else{
              dataSort[item1] = "ASC";
            } 
          }
        }

        dataSort["id"] = "DESC";

        var query = { 
          start: (page - 1) * itemsPerPage,
          length: itemsPerPage,
          main: this.searchMain,
          search: this.tContentsSearch
        }

        // ApiService.setHeader();
        ApiService.post("role/list", query, 1).then(({ data }) => {
          resolve(data)
        }).catch(({ response }) => {
          reject(response);
        })

      })
    },

    submitSearchTContents(){
      this.tContentsOptions.page = 1;
      this.loadMainContent();
    },

    showFormDialog(content){
      if(this.$refs.vForm) this.$refs.vForm.reset()
      this.selectedModule = [];
      this.selectedModuleTreeEdit = [];
      this.selectedModuleTree = this.dataModule.map(() => []);


      setTimeout(() => {
        this.form = {};
        this.title_form = "Tambah";
        if(content){
          this.title_form = "Edit";
          this.form = { ...content };

          content.role_module.forEach((e) => {
            this.selectedModule.push(e.module_id);
            this.selectedModuleTreeEdit[e.module_id] = e.role_module_permision.map(m => m.permisssion_id)
          });

          this.dataModule.map((data, index)=>{
            if(typeof this.selectedModuleTreeEdit[data.value] !== 'undefined'){
              this.selectedModuleTree[index] = this.selectedModuleTreeEdit[data.value];
            }
          });
          
        }
        this.dialog = true;
      }, 50);
    },

    showFormDialogDetail(content){
      setTimeout(() => {
        this.formDetail = {};
        this.formDetail.id = content.id;
        this.formDetail.nama = content.nama;
        this.formDetail.tipe = content.tipe;
        this.formDetail.kode = content.kode;
        this.formDetail.role_module = content.role_module;
        this.dialogDetail = true;
      }, 50);
    },

    submitForm(){
      if(!this.$refs.vForm.validate()) return;

      var msg = "Simpan";
      if(this.form.id) msg = "Ubah";

      this.$swal({
        title: 'Peringatan',
        html: 'Yakin ingin <strong>'+msg+'</strong> data ini ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Submit"
      }).then((result) => {
        if(result.isConfirmed){

          var query = {
            main: this.searchMain,
            data: {...this.form}
          }
          

          query.data.module = this.selectedModule;
          query.data.module_permision = this.selectedModuleTree;
          
          // console.log("dataModule");
          // console.log(this.dataModule);
          // console.log("selectedModuleTree");
          // console.log(this.selectedModuleTree);

          


          for (let index = 0; index < this.selectedModuleTree.length; index++) {
            for (let j = 0; j < this.dataModule.length; j++) {
              // console.log('DATA MODUL');
              // console.log(this.dataModule[j]['value']);
              // console.log('NO URUT');
              // console.log(j);

              this.permisionObj[j] = {
                value : this.dataModule[j]['value'],
                permissions  : this.selectedModuleTree[j]
              }
            }

          }

          query.data.module = this.selectedModule;
          query.data.module_permision = this.permisionObj;
          
          var url = "role/create";
          if(this.form.id){
            url = "role/update";
          }
          ApiService.post(url, query, 1).then(({data}) => {
            this.dialog = false;
            this.$swal("Success", data.message, "success");
            this.loadMainContent();
          }).catch(({response}) => {
            this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
          })
        }
      })
    },

    submitDelete(val){
        this.$swal({
          title: 'Peringatan',
          html: 'Yakin ingin hapus data ini ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Submit"
        }).then((result) => {
          if(result.isConfirmed){
            var query = {
              main: this.searchMain,
              data: {
                id: val
              }
            }

            ApiService.post("role/delete", query, 1).then(({data}) => {
              this.$swal("Success", data.message, "success");
              this.loadMainContent();
            }).catch((response)=>{
              this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
            })
          }
        })
    },

    // :loader
    loadMainContent(){
      this.tContents = [];
      this.tContentsTotal = 0;
      this.tContentsLoading = true;
      this.getMainList().then(data => {
        this.tContentsLoading = false;
        if(data.detail){
          this.tContents = data.detail
        }else{
          this.tContents = []
        }
        this.tContentsTotal = data.count
      }).catch((response)=>{
        this.tContents = []
        this.tContentsTotal = 0;
        this.tContentsLoading = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },

    loadModule(){
      this.loadingModule = true;
      MasterService.loadMaster("dropdown", "moduletree").then(({data})=>{
        this.loadingModule = false;
        
        data.data.forEach(e => {
          this.selectedModuleTree.push(
            {
              value : e.value,
              permissions : []
            }
          );
        });
        this.dataModule = data.data;
      }).catch((response)=>{
        this.loadingModule = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    // :end loader
  },
  
};